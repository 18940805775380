.detail-container {
  max-width: 1024px;
  height: '100%';
  margin: 0 auto;
}

.back-container {
  display: flex;
  max-height: 50px;
  margin-bottom: 30px;

  a {
    color: #000000;
  }
}

.download-container {
  display: flex;
  margin: 20px;
  justify-content: center;

  a {
    color: #000000;
  }
}

.download-link {
  max-width: 110px;
  display: flex;
  margin: 50px;
}

.preacher-icon {
  margin-right: 10px;
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;

  img{
    height: 100%;
    width: auto;
  }
}
// https://stackoverflow.com/a/54924505/1486742
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
